<template>
  <div class="content" style="text-align: left">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>应试管理</BreadcrumbItem>
      <BreadcrumbItem>测试数据</BreadcrumbItem>
    </Breadcrumb>
    <div class="bg-shadow func-bar">
      <div class="button-container">
        <!--        <Button class="filter-btn btn-shadow" size="large" type="primary" @click="isEdit = false;addCandidate = true">-->
        <!--          <svg class="icon" aria-hidden="true">-->
        <!--            <use xlink:href="#icon-xinzeng"></use>-->
        <!--          </svg>-->
        <!--          添加-->
        <!--        </Button>-->
      </div>
      <div class="search-container">
        <Input class="search" v-model="search.candidateName" search placeholder="请输入姓名" @on-search="advSearch" />
        <div class="advanced-container" @click="isSpread = !isSpread">
          <span>高级搜索</span>
          <svg class="icon" :class="{ spread: isSpread }" aria-hidden="true">
            <use xlink:href="#icon-bottom"></use>
          </svg>
        </div>
        <div @mouseleave="isSpread = false" class="advanced-board bg-shadow" :class="{ show: isSpread }">
          <Form class="form" :model="search" :label-width="50">
            <FormItem label="姓名">
              <Input v-model="search.candidateName" placeholder="请输入姓名" />
            </FormItem>
            <FormItem label="考试名">
              <Input v-model="search.examName" placeholder="请输入考试名称" />
            </FormItem>
            <FormItem label="状态">
              <RadioGroup v-model="search.answerPaperStatus">
                <Radio label="1">未开始</Radio>
                <Radio label="2">做题中</Radio>
                <Radio label="4">已交卷</Radio>
              </RadioGroup>
            </FormItem>
          </Form>
          <div style="display: flex;margin-left: 20px">
            <Button class="filter-btn search btn-shadow" type="primary" @click="reset">重置</Button>
            <Button class="filter-btn search btn-shadow" type="primary" @click="advSearch">搜索</Button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-container bg-shadow">
      <Table class="flex-table" stripe :columns="columns" :data="tableData" :loading="loading" @on-row-click="toDetail">
        <template slot-scope="{ row, index }" slot="name">
          <span>{{ row.candidateName }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="examName">
          <span>{{ row.examName }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="score">
          <span>{{ row.score }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="department">
          <span>{{ row.departmentName }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="duration">
          <span>{{ timeStamp(row.duration) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="status">
          <span>{{ status(row.answerPaperStatus) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="startTime">
          <span>{{ $formatTime(row.startTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="submitTime">
          <span>{{ $formatTime(row.submitTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="expiresTime">
          <span>{{ $formatTime(row.expiresTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
      </Table>
      <div class="page">
        <div style="float: right;">
          <Page
            show-total
            show-sizer
            show-elevator
            :total="total"
            :current="page + 1"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
          />
        </div>
      </div>
    </div>
    <Modal v-model="resModal" title="评测结果">
      <div v-for="(item, index) in result" :key="index" style="margin-top: 5px">
        <span style="font-size: 1.2em;font-weight: bold">{{ item.sectionName }}: {{ item.score }}分</span><br />
        <span style="font-size: 1.1em" v-html="item.result === null ? '' : item.result.replace(/(\r\n)|(\n)/g, '<br>')"></span>
      </div>
      <div slot="footer">
        <Button type="primary" @click="resModal = false">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import exam from '@api/exam'
export default {
  name: 'Result',
  data() {
    return {
      resModal: false,
      result: [],
      total: 0,
      loading: false,
      page: 0,
      size: 10,
      isSpread: false,
      tableData: [],
      columns: [
        {
          title: '姓名',
          slot: 'name',
          className: 'name-column'
        },
        {
          title: '考试名称',
          slot: 'examName',
          className: 'name-column'
        },
        {
          title: '得分',
          slot: 'score',
          className: 'name-column'
        },
        {
          title: '应试部门',
          slot: 'department',
          className: 'department-column'
        },
        {
          title: '用时',
          slot: 'duration',
          className: 'name-column'
        },
        {
          title: '状态',
          slot: 'status',
          className: 'name-column'
        },
        {
          title: '开始时间',
          slot: 'startTime',
          className: 'time-column'
        },
        {
          title: '提交时间',
          slot: 'submitTime',
          className: 'time-column'
        },
        {
          title: '失效时间',
          slot: 'expiresTime',
          className: 'time-column'
        }
      ],
      search: {
        answerPaperStatus: '',
        candidateName: '',
        examName: ''
      },
      isSearch: false
    }
  },
  created() {
    this.initData(0, 10, {})
  },
  methods: {
    toDetail(row) {
      if (row.type === 1) {
        this.$router.push({
          path: '/candidate/resultDetail',
          query: {
            result: true,
            name: row.candidateName,
            examName: row.examName,
            id: row.answerPaperId,
            score: row.score
          }
        })
      } else {
        this.result = []
        exam.getEvaRes(row.answerPaperId).then(res => {
          for (let i in res.res.evaluationResults) {
            this.result.push(res.res.evaluationResults[i])
          }
          this.resModal = true
        })
      }
    },
    reset() {
      this.page = 0
      this.size = 0
      this.search = {
        answerPaperStatus: '',
        candidateName: '',
        examName: ''
      }
      this.isSpread = false
      this.isSearch = false
      this.initData(0, 10, {})
    },
    advSearch() {
      this.isSearch = true
      this.page = 0
      this.size = 10
      this.searchData(0, 10)
    },
    searchData(page, size) {
      this.loading = true
      this.isSpread = false
      exam.getResult(page, size, this.search).then(res => {
        this.tableData = res.res.data
        this.total = res.res.total
        this.loading = false
      })
    },
    timeStamp(second_time) {
      let time = parseInt(second_time) + '秒'
      if (parseInt(second_time) > 60) {
        let second = parseInt(second_time) % 60
        let min = parseInt(second_time / 60)
        time = min + '分' + second + '秒'

        if (min > 60) {
          min = parseInt(second_time / 60) % 60
          let hour = parseInt(parseInt(second_time / 60) / 60)
          time = hour + '小时' + min + '分' + second + '秒'

          if (hour > 24) {
            hour = parseInt(parseInt(second_time / 60) / 60) % 24
            let day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24)
            time = day + '天' + hour + '小时' + min + '分' + second + '秒'
          }
        }
      }
      return time === 'NaN秒' ? '' : time
    },
    status(status) {
      switch (status) {
        case 1:
          return '未开始'
        case 2:
          return '做题中'
        case 3:
          return '已暂停'
        case 4:
          return '已交卷'
        case 5:
          return '待批阅'
        case 6:
          return '已批阅'
        case 7:
          return '已退出'
        case 8:
          return '已超时'
      }
    },
    initData(page, size, data) {
      this.loading = true
      exam.getResult(page, size, data).then(res => {
        this.tableData = res.res.data
        this.total = res.res.total
        this.loading = false
      })
    },
    changePage(val) {
      this.page = val - 1
      this.initData(this.page, this.size, this.search)
    },
    changePageSize(val) {
      this.size = val
      this.initData(this.page, this.size, this.search)
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../theme/variables';

.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.func-bar {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 20px;

  .filter-btn {
    margin-right: 20px;
    min-width: 100px;
  }

  .search-container {
    display: flex;
    align-items: center;
    position: relative;

    .search ::v-deep .ivu-input {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;

      &:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }
    }

    .advanced-container {
      flex: 1 0 auto;
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
      padding-right: 20px;

      svg.icon {
        transition: all @default-transition;
        transform: rotate(0deg);
        margin-left: 8px;
      }

      svg.icon.spread {
        transform: rotate(180deg);
      }
    }

    .advanced-board {
      position: absolute;
      z-index: 1;
      top: 56px;
      right: 0;
      width: 320px;
      height: 0;
      overflow: hidden;
      border-right: solid 5px @primary-color;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      transition: all @default-transition;

      &.show {
        height: 250px;
      }

      .search {
        margin-right: 20px;
        height: 40px;
        border-radius: 4px;
      }

      .form {
        width: 300px;
        margin-top: 20px;
      }
    }
  }
}

.table-container {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .flex-table {
    border-bottom: 1px solid #e8eaec;

    .status-container {
      display: flex;
      align-items: center;

      .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    .operation-tooltip {
      margin-right: 10px;
      cursor: pointer;
      color: @primary-color;
      transition: all @default-transition;

      &:hover {
        color: @primary-hover-color;
      }
    }

    .name-column {
      width: 10%;
    }

    .time-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .department-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .email-column {
      width: 15%;
    }

    .phone-column {
      width: 15%;
    }

    .operation-column {
      width: 15%;
    }
  }

  .page {
    flex: 0 0 auto;
    padding: 20px;
    font-size: 14px;

    .page-options {
      float: left;
      height: 32px;
      line-height: 32px;
    }
  }
}
::v-deep.flex-table.ivu-table-wrapper .ivu-table .ivu-table-body {
  overflow: hidden;
}
</style>
